export const feature = (t) => ([
    {
        id:1,
        img: 'plagiarism.svg',
        title: t("feature_title1"),
        label: true,
        desc: t("feature_desc1")
    },
    {
        id:2,
        img: 'revisions.svg',
        title: t("feature_title2"),
        label: false,
        desc: t("feature_desc2")
    },
    {
        id:3,
        img: 'highQuality.svg',
        title: t("feature_title3"),
        label: false,
        desc: t("feature_desc3")
    },
    {
        id:4,
        img: 'swiftDelivery.svg',
        title: t("feature_title4"),
        label: false,
        desc: t("feature_desc4")
    }
]);

export const featuresList = [
    "✅ Plagiarism Free Content",
    "👌 Perfect Formatting",
    "❎ No Hidden Charges",
    "⭐ Highest Quality",
    "🪙 Safe Payment Methods",
    "🗂️ Your data is 100% Safe",
    "😊 Unlimited Free Editing"
];
