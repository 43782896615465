import React from "react";
// scss
import featureCss from "./feature.module.scss";

import {feature, featuresList} from "./data";
import Link from "next/link";
import {useTranslation} from "next-i18next";
import Image from "next/image";
import {Col, Container, Row} from "react-bootstrap";

function Feature(props) {

  const { linkLocale } = props;
  const { t } = useTranslation("common");
  const featureData = feature(t);

  return <>
    <section className={`${featureCss.feature} p-0`}>
      <Container>
        <div className="text-center">
          <h2 className="section-title">{t("feature_section_title")}</h2>
          <p className="desc">{t("feature_section_desc")}</p>
        </div>
        <Row>
          <Col lg={8}>
            {
              featureData.map(list => (
                <div className={featureCss.featureBox} key={list.id}>
                  {list.label ? <div className={featureCss.bedge}>top</div> : null}
                  <div className={featureCss.featureImg}>
                    <Image src={`/${list.img}`} alt="content" title="Feature" width={20} height={21} />
                  </div>
                  <div className={featureCss.detail}>
                    <h3 className={featureCss.title}>{list.title}</h3>
                    <p className={featureCss.desc}>{list.desc}</p>
                  </div>
                </div>
              ))
            }
          </Col>
          <Col lg={4} className={featureCss.rightDetails}>
              <h3 className={featureCss.title}>
                <span>Get all the features for <span className={featureCss.success}> {t("currency")}65.86</span>&nbsp; Free Per Page</span>
              </h3>
              <table className={`${featureCss.boxList} ${featureCss.boxTable}`}>
                <tbody>
                  {
                    featuresList.map((item, index) => (
                      <tr key={index}>
                        <td><p>{item}</p></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <Link
                href={"/top-writers"}
                locale={linkLocale}
                prefetch={false}
                className="btn secondary-btn inline-button-click"
                id="featureHireWriter">
                Hire a Writer
              </Link>
          </Col>
        </Row>
      </Container>
    </section>
  </>;
}

export default Feature;